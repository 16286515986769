import React from "react";
import "../../style/App.css";
import { Helmet } from "react-helmet";


import profile from "./config.json";

import Background from "../../components/background";

import Prefinery from "../../components/prefinery.js";

import catalogTr from "../../locales/tr/messages.js";
import catalogPt from "../../locales/pt/messages.js";
import catalogDe from "../../locales/de/messages.js";
import catalogEn from "../../locales/en/messages.js";



import { I18nProvider } from "@lingui/react";
import { Trans } from "@lingui/macro";
import detectBrowserLanguage from 'detect-browser-language'

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles";


import light from "../../themes/light.js";
import dark from '../../themes/dark.js';


const catalogs = { tr: catalogTr, de: catalogDe, pt: catalogPt, en: catalogEn };

const logo = require("./img/logo.png");
const bg = require("./img/bg.png");
const setbg = { backgroundImage: "url(" + bg + ")" };

const chrome = require("../../style/img/chrome.png");
const edge = require("../../style/img/edge.png");

const post = profile.prefineryOptions.post
const honeypot = profile.prefineryOptions.honeypot
const browserlang = detectBrowserLanguage().toString().substring(0,2)


class Application extends React.Component {


	render() {
		const dark_theme = profile.dark_theme;
		let theme
         if(dark_theme === 'true') {
			theme = responsiveFontSizes(dark);
            require('../../style/dark.css');
         } else {
         	theme = responsiveFontSizes(light);
            require('../../style/light.css');
         }

		return (

			<I18nProvider language={browserlang} catalogs={catalogs}>
			<ThemeProvider theme={theme}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>{profile.title} Startpage App for Superfans</title>
					<meta name="description" content="Sign up for free now to be among the first fans to receive an invitation for early access to the mobile version" />
					<link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
					<script
						src={profile.prefineryOptions.script}
						rel="preconnect"
						async
						defer
					></script>
				</Helmet>
				<Background bg={setbg} />
				<div className="content">
					<div className="onboard">
						<Box mb={2} mt={2}>
							<Box mb={2}>
								<img height="96px" src={"" + logo} alt="Logo" />
							</Box>
							<Box mb={2}>
								<Typography variant="h3">
									<span><b>{profile.title}</b><br/></span>
									<Trans>Startpage App for Superfans</Trans>
								</Typography>
							</Box>
							<Box mb={2}>
								<Typography>
									<Trans>
										Unfortunately, the startpage app is
									</Trans>
									&nbsp;
										<b><Trans>
											only available for <br/><span className="icon"><img src={chrome} alt="Google Chrome"/></span>Google Chrome and
											<br/><span className="icon"><img src={edge} alt="Google Chrome"/></span>Microsoft Edge
										</Trans></b>
								</Typography>
							</Box>
							<Prefinery post={post} honeypot={honeypot} />
							<Typography>
								<Trans>
									Sign up for free now to be among the first fans to
								</Trans>
								<b>&nbsp;
									<Trans>
										receive an invitation for early access to the mobile version
									</Trans>
								</b>
							</Typography>
							<Box mt={8}>
							<div className="privacy">
By agreeing to use this service you accept Prefinery's <a href="https://www.prefinery.com/privacy">privacy policy</a>.
</div>
						</Box>
						</Box>
					</div>
				</div>
			</ThemeProvider>
		</I18nProvider>
		);
	}
}

export default Application;
