import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import { Trans } from '@lingui/macro';

class Prefinery extends React.Component {
	render () {
		return (
				<Box mt={2} mb={2}>
					<div id="pfy_embed_signup" className="signup">
						<form action={this.props.post} id="pfy_signup_form" className="pfy-signup-form" method="post" acceptCharset="UTF-8" >

							<input id="referrer" name="referrer" type="hidden" defaultValue="" />
							<input id="referral_token" name="referral_token" type="hidden" defaultValue="" />

							<div aria-hidden="true" style={{position: "absolute", left: "-5000px"}}>
							  <input type="text" name={this.props.honeypot} tabIndex="-1" defaultValue="" />
							</div>

							<Box mb={2}>
								<TextField type="email" name="user[profile][email]" id="pfy_user_profile_email" label="Email" fullWidth margin="none" variant="filled" required/>		
							</Box>		
							<Button type="submit" size="large" variant="contained" fullWidth ><Trans>GET EARLY ACCESS</Trans></Button>
						</form>
					</div>
				</Box>	
		);
	}
};


export default Prefinery