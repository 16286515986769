import React from 'react';

class Background extends React.Component {
	render () {
		return (
				<div className="cover">
					<div className="bg" style={this.props.bg}></div>
					<div className="bg-color"></div>
				</div>
		);
	}
};


export default Background;
