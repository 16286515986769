import React from "react";
import { render } from "react-dom";
import "./style/index.css";

import { BrowserRouter as Router, Route } from "react-router-dom";

// import * as serviceWorker from "./serviceWorker";

import Albaberlin from "./brand_src/albaberlin/index";
import Fcbb from "./brand_src/fcbb/index";
import Corinthians from "./brand_src/corinthians/index";
import Inextremo from "./brand_src/inextremo/index";
import Cruzeiro from "./brand_src/cruzeiro/index";
import Lbf from "./brand_src/lbf/index";
import Voleibrasil from "./brand_src/voleibrasil/index";
import Coimbra from "./brand_src/coimbra/index";
import Coritiba from "./brand_src/coritiba/index";
import Ehcredbull from "./brand_src/ehcredbull/index";
import Toughmudder from "./brand_src/toughmudder/index";
import Scp from "./brand_src/scp/index";
import Qpr from "./brand_src/qpr/index";
import Afcmg from "./brand_src/afcmg/index";
import Cod from "./brand_src/cod/index";
import Sportclubdorecife from "./brand_src/sportclubdorecife/index";
import Ecvitoria from "./brand_src/ecvitoria/index";
import Lowdowndeep from "./brand_src/lowdowndeep/index";
import Felipeprior from "./brand_src/felipeprior/index";
import Cearasporting from "./brand_src/cearasporting/index";
import Lucinhobarreto from "./brand_src/lucinhobarreto/index";
import Ligaportal from "./brand_src/ligaportal/index";
import Barbaralabres from "./brand_src/barbaralabres/index";
import Luizavaldetaro from "./brand_src/luizavaldetaro/index";
import Vivokeyd from "./brand_src/vivokeyd/index";
import Pontepreta from "./brand_src/pontepreta/index";
import Wycombewanderers from "./brand_src/wycombewanderers/index";
import Chrisburkard from "./brand_src/chrisburkard/index";
import Fenerbahce from "./brand_src/fenerbahce/index";
import Westbromwich from "./brand_src/westbromwich/index";
import Lcfc from "./brand_src/lcfc/index";
import Ecrbs from "./brand_src/ecrbs/index";
import Mickyfinn from "./brand_src/mickyfinn/index";
import Besiktas from "./brand_src/besiktas/index";
import Vilanova from "./brand_src/vilanova/index";
import Wswfc from "./brand_src/wswfc/index";
import Wolves from "./brand_src/wolves/index";
import Alinebarros from "./brand_src/alinebarros/index";
import Aquabumps from "./brand_src/aquabumps/index";
import W7mgaming from "./brand_src/w7mgaming/index";
import Tirullipa from "./brand_src/tirullipa/index";
import Osascovoleibolclube from "./brand_src/osascovoleibolclube/index";
import Clubedoremo from "./brand_src/clubedoremo/index";
import Esporteclubebahia from "./brand_src/esporteclubebahia/index";
import Andrevaladao from "./brand_src/andrevaladao/index";
import Globofc from "./brand_src/globofc/index";
import Isadorapompeo from "./brand_src/isadorapompeo/index";
import Veszpremkosar from "./brand_src/veszpremkosar/index";
import Emilygarcia from "./brand_src/emilygarcia/index";
import Cardiffcityfc from "./brand_src/cardiffcityfc/index";
import Mygolf from "./brand_src/mygolf/index";
import Manishlakhani from "./brand_src/manishlakhani/index";
import Csa from "./brand_src/csa/index";
import Paysandu from "./brand_src/paysandu/index";
import Interdelimeira from "./brand_src/interdelimeira/index";
import Goiasec from "./brand_src/goiasec/index";
import Figueirense from "./brand_src/figueirense/index";
import Vitoriasc from "./brand_src/vitoriasc/index";
import Falcokc from "./brand_src/falcokc/index";
import Carmengeiss from "./brand_src/carmengeiss/index";
import Trabzonspor from "./brand_src/trabzonspor/index";
import Everton from "./brand_src/everton/index";
import Cafc from "./brand_src/cafc/index";
import Dannyavila from "./brand_src/dannyavila/index";
import Udibiza from "./brand_src/udibiza/index";
import Ibizasonica from "./brand_src/ibizasonica/index";
import Clubpuebla from "./brand_src/clubpuebla/index";
import Ajboxing from "./brand_src/ajboxing/index";
import Ogcnice from "./brand_src/ogcnice/index";
import Etoiledusahel from "./brand_src/etoiledusahel/index";
import Francabasquete from "./brand_src/francabasquete/index";
import Survivor from "./brand_src/survivor/index";
import Magnusfutsal from "./brand_src/magnusfutsal/index";
import Fluminense from "./brand_src/fluminense/index";
import Atleticogoianiense from "./brand_src/atleticogoianiense/index";
import Paokfc from "./brand_src/paokfc/index";


const Application = () => (
  <Router basename="/">
    <Route
      exact
      path="/"
      render={() => (window.location = "https://brandturbo.vip")}
    />
    <Route path="/fcbb" component={Fcbb} />
    <Route path="/corinthians" component={Corinthians} />
    <Route path="/albaberlin" component={Albaberlin} />
    <Route path="/inextremo" component={Inextremo} />
    <Route path="/cruzeiro" component={Cruzeiro} />
    <Route path="/lbf" component={Lbf} />
    <Route path="/voleibrasil" component={Voleibrasil} />
    <Route path="/coimbra" component={Coimbra} />
    <Route path="/coritiba" component={Coritiba} />
    <Route path="/ehcredbull" component={Ehcredbull} />
    <Route path="/toughmudder" component={Toughmudder} />
    <Route path="/scp" component={Scp} />
    <Route path="/qpr" component={Qpr} />
    <Route path="/afcmg" component={Afcmg} />
    <Route path="/cod" component={Cod} />
    <Route path="/sportclubdorecife" component={Sportclubdorecife} />
    <Route path="/ecvitoria" component={Ecvitoria} />
    <Route path="/lowdowndeep" component={Lowdowndeep} />
    <Route path="/felipeprior" component={Felipeprior} />
    <Route path="/ligaportal" component={Ligaportal} />
    <Route path="/lucinhobarreto" component={Lucinhobarreto} />
    <Route path="/cearasporting" component={Cearasporting} />
    <Route path="/barbaralabres" component={Barbaralabres} />
    <Route path="/luizavaldetaro" component={Luizavaldetaro} />
    <Route path="/vivokeyd" component={Vivokeyd} />
    <Route path="/pontepreta" component={Pontepreta} />
    <Route path="/wycombewanderers" component={Wycombewanderers} />
    <Route path="/chrisburkard" component={Chrisburkard} />
    <Route path="/fenerbahce" component={Fenerbahce} />
    <Route path="/lcfc" component={Lcfc} />
    <Route path="/westbromwich" component={Westbromwich} />
    <Route path="/ecrbs" component={Ecrbs} />
    <Route path="/mickyfinn" component={Mickyfinn} />
    <Route path="/besiktas" component={Besiktas} />
    <Route path="/vilanova" component={Vilanova} />
    <Route path="/wswfc" component={Wswfc} />
    <Route path="/wolves" component={Wolves} />
    <Route path="/alinebarros" component={Alinebarros} />
    <Route path="/aquabumps" component={Aquabumps} />
    <Route path="/w7mgaming" component={W7mgaming} />
    <Route path="/tirullipa" component={Tirullipa} />
    <Route path="/osascovoleibolclube" component={Osascovoleibolclube} />
    <Route path="/clubedoremo" component={Clubedoremo} />
    <Route path="/esporteclubebahia" component={Esporteclubebahia} />
    <Route path="/andrevaladao" component={Andrevaladao} />
    <Route path="/globofc" component={Globofc} />
    <Route path="/veszpremkosar" component={Veszpremkosar} />
    <Route path="/isadorapompeo" component={Isadorapompeo} />
    <Route path="/emilygarcia" component={Emilygarcia} />
    <Route path="/cardiffcityfc" component={Cardiffcityfc} />
    <Route path="/mygolf" component={Mygolf} />
    <Route path="/manishlakhani" component={Manishlakhani} />
    <Route path="/csa" component={Csa} />
    <Route path="/paysandu" component={Paysandu} />
    <Route path="/interdelimeira" component={Interdelimeira} />
    <Route path="/goiasec" component={Goiasec} />
    <Route path="/figueirense" component={Figueirense} />
    <Route path="/vitoriasc" component={Vitoriasc} />
    <Route path="/falcokc" component={Falcokc} />
    <Route path="/carmengeiss" component={Carmengeiss} />
    <Route path="/trabzonspor" component={Trabzonspor} />
    <Route path="/everton" component={Everton} />
    <Route path="/cafc" component={Cafc} />
    <Route path="/dannyavila" component={Dannyavila} />
    <Route path="/udibiza" component={Udibiza} />
    <Route path="/ibizasonica" component={Ibizasonica} />
    <Route path="/clubpuebla" component={Clubpuebla} />
    <Route path="/ajboxing" component={Ajboxing} />
    <Route path="/ogcnice" component={Ogcnice} />
    <Route path="/etoiledusahel" component={Etoiledusahel} />
    <Route path="/francabasquete" component={Francabasquete} />
    <Route path="/survivor" component={Survivor} />
    <Route path="/magnusfutsal" component={Magnusfutsal} />
    <Route path="/fluminense" component={Fluminense} />
    <Route path="/atleticogoianiense" component={Atleticogoianiense} />
    <Route path="/paokfc" component={Paokfc} />
  </Router>
);

render(<Application />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
