import { createMuiTheme } from '@material-ui/core/styles';
import { grey, blue } from '@material-ui/core/colors';

export default createMuiTheme({
	palette: {
		primary: {
			main: grey[100],
		},
	},
	overrides: {
	  MuiInputBase: {
	    input: {
	      "&::placeholder": {
	        color: "white",
	        fontWeight: 700,
	      },
	      color: "white",
	      backgroundColor: grey[900],	
	      fontWeight: 700
	  },
	  },
	 MuiButton: {
	    contained: {
			color: "white",
	     	backgroundColor: blue[900],
			"&:hover": {
	        color: "black",
	        backgroundColor: blue[800],
	      },
	    }
	  },
	  MuiInputLabel: {
	    root: {
	      color: "white"
	    }
	  }
	}
});



