import { createMuiTheme } from '@material-ui/core/styles';
import { grey, blue } from '@material-ui/core/colors';

export default createMuiTheme({
	palette: {
		primary: {
			main: grey[900],
		}
	},
	overrides: {
	  MuiInputBase: {
	    input: {
	      "&::placeholder": {
	        color: "white",
	        fontWeight: 700,
	      },
	      fontWeight: 700,
	  },
	  },
	 MuiButton: {
	    contained: {
			color: "white",
			fontWeight: 700,
	     	backgroundColor: blue[900],
			"&:hover": {
	        backgroundColor: blue[800],
	      },
	    }
	  },
	  MuiInputLabel: {
	  }
	}
});

